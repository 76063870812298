.video-section {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 20px;
}

/* Update the background pseudo-element to match About.css style */
.video-section::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../assets/images/bg1.webp');
    background-position: center;
    opacity: 0.1;
    pointer-events: none;
    z-index: 0;
}

/* Remove or comment out the ::before element since we don't need the extra darkening */
/* .video-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
} */

.video-container {
    width: 60%;
    max-width: 1040px;
    aspect-ratio: 16/9;
    position: relative;
    z-index: 2;
    margin: 40px auto;
}

/* Style for the iframe */
.video-container iframe {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
    .video-section {
        height: auto;
        min-height: unset;
        padding: 40px 15px;
    }
    
    .video-container {
        width: 90%;
        margin: 20px auto;
    }
}

@media (max-width: 480px) {
    .video-section {
        padding: 30px 10px;
    }
    
    .video-container {
        width: 95%;
        margin: 15px auto;
    }
}

.loading {
    width: 560px;
    height: 315px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
}