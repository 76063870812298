.contact-section-home {
    background-color: rgba(9, 18, 53, 1);
}

.navbar-wrapper {
    position: sticky;
    top: 0;
    z-index: 1000;
}


html {
    scroll-behavior: smooth;
}
