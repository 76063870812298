.floorplan-section {
    background-color: rgba(246, 214, 115, 1);
    padding: 40px 20px;
    text-align: center;
    min-height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.floorplan-container {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    min-height: 600px;
    padding: 20px 0;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 1;
    visibility: visible;
}

.floorplan-title {
    margin-bottom: 20px;
}

.floorplan-title h2 {
    margin: 0;
    font-size: clamp(2.5rem, 4vw, 4rem);
    color: rgba(9, 18, 53, 1);
}

.title-decoration-floorplan {
    width: min(400px, 80%);
    height: auto;
}

.floorplan-buttons {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.button-group {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.tower-button {
    padding: 12px 30px;
    border: 2px solid #091235;
    background: transparent;
    color: #091235;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.tower-button:hover {
    background: #091235;
    color: white;
}

.tower-button.active {
    background: #091235;
    color: white;
}

@media (max-height: 768px) {
    .floorplan-img-container {
        height: 40vh;
        min-height: 250px;
    }
    
    .floorplan-title h2 {
        font-size: calc(1.2rem + 1vw);
    }
    
    .bhk-button {
        padding: 10px 30px;
    }
}

@media (max-width: 768px) {
    .button-group {
        gap: 10px;
    }

    .tower-button {
        padding: 10px 20px;
        font-size: 1rem;
    }
}

.floorplan-img-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    min-height: 300px;
    height: 50vh;
    max-height: 600px;
}

.floorplan-img {
    width: min(100%, 800px);
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.signup-button-container {
    text-align: center;
}

.signup-button {
    background-color: rgba(9, 18, 53, 1);
    color: rgba(246, 214, 115, 1);
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 25px;
    font-weight: 700;
    font-size: 1.2rem;
}

.scroll-reveal {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.scroll-reveal.visible {
    opacity: 1;
    transform: translateY(0);
}

.floorplan-title.scroll-reveal {
    transition-delay: 0.2s;
}

.floorplan-buttons.scroll-reveal {
    transition-delay: 0.4s;
}

.floorplan-img-container.scroll-reveal {
    transition-delay: 0.6s;
}

.signup-button-container.scroll-reveal {
    transition-delay: 0.8s;
}

