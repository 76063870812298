.overview-section {
    position: relative;
    background: linear-gradient(to right, 
        #F8D568 25%, /* Yellow color for left 25% */
        #0A1B3F 25%  /* Dark blue color for remaining 75% */
    );
    padding: 4rem 0;
}

.image-container {
    position: relative;
    width: 100%;
    padding: 20px;
}

.yellow-frame {
    position: relative;
    width: 100%;
}

.yellow-frame::after {
    content: '';
    position: absolute;
    top: -30px;
    /* right: -20px; */
    left: 30px;
    width: 95%;
    height: 95%;
    border: 4px solid #F8D568;
}

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: -2rem;
}

.yellow-frame {
    position: relative;
    border: none;
    padding: 1rem;
    margin: 2rem;
    transform: translate(0, 2rem);
    /* transform: translateY(-2rem); */
}

.yellow-frame img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.overview-content {
    color: white;
    padding: 4rem 2rem;
}

.overview-title {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #F8D568;
}

.overview-subtitle {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #F8D568;
}

.overview-description {
    font-size: 1.8rem;
    line-height: 1.8;
    color: #F8D568;
}

@media (max-width: 1200px) {
    .overview-title {
        font-size: 3.5rem;
    }

    .overview-subtitle {
        font-size: 1.8rem;
    }

    .overview-description {
        font-size: 1.5rem;
        line-height: 1.6;
    }
}

@media (max-width: 992px) {
    .overview-section {
        background: linear-gradient(to bottom, rgba(246, 214, 115, 1) 40%, rgba(9, 18, 53, 1) 40%); /* Single color background for smaller screens */
        padding: 3rem 0;
    }

    .image-container {
        margin-left: 0;
        padding: 15px;
        max-width: 600px;
        margin: 0 auto;
    }

    .yellow-frame::after {
        top: -20px;
        left: 20px;
        border-width: 3px;
    }

    .overview-content {
        padding: 3rem 1.5rem;
        text-align: center;
    }

    .overview-title {
        font-size: 3rem;
    }

    .overview-subtitle {
        font-size: 1.6rem;
    }

    .overview-description {
        font-size: 1.3rem;
        line-height: 1.5;
    }
}

@media (max-width: 768px) {
    .overview-section {
        padding: 2rem 0;
    }

    .image-container {
        padding: 10px;
    }

    .yellow-frame {
        margin: 1rem;
        transform: translate(0, 1rem);
    }

    .yellow-frame::after {
        top: 55px;
        left: -30px;
        border-width: 3px;
    }

    .overview-content {
        padding: 2rem 1rem;
    }

    .overview-title {
        font-size: 2.5rem;
    }

    .overview-subtitle {
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
    }

    .overview-description {
        font-size: 1.1rem;
        line-height: 1.4;
    }
}

@media (max-width: 480px) {
    .overview-section {
        padding: 1.5rem 0;
    }

    .yellow-frame {
        margin: 0.5rem;
    }

    .yellow-frame::after {
        top: -20px;
        left: 20px;
        border-width: 3px;
    }

    .overview-title {
        font-size: 2rem;
    }

    .overview-subtitle {
        font-size: 1.2rem;
    }

    .overview-description {
        font-size: 1rem;
    }
}


