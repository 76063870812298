.about-section {
    padding: 80px 0;
    background: #fff;
    position: relative;
    overflow: hidden;
    /* height: 1vh; */
    z-index: 1;
}

.about-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../assets/images/bg1.webp');
    /* background-size: cover; */
    background-position: center;
    /* background-repeat: no-repeat; */
    opacity: 0.1;
    pointer-events: none;
    z-index: -1;
}

/* Left Content Styles */
.luxury-title {
    /* font-family: 'Playfair Display', serif; */
     font-family: "Libre Baskerville", serif;
    font-size: 3.5rem;
    color: #0A0F2C;
    margin-bottom: 30px;
    line-height: 1.2;
}

.about-description {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.8;
    color: #333;
    margin-bottom: 30px;
    /* background: rgba(173, 216, 230, 0.2); */
    padding: 20px;
    border-radius: 8px;
}

/* .book-now-btn {
    background: #FFD700;
    border: none;
    padding: 15px 40px;
    border-radius: 30px;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.book-now-btn:hover {
    transform: translateY(-3px);
} */

.button-about {
    text-align: center;
    
}

.about-button-form{
    background-color: rgba(246, 214, 115, 1);
    padding: 1rem;
    border-radius: 25px;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    color: rgba(9, 18, 53, 1);
    margin-top: 2rem;
    cursor: pointer;
    transform: transform 0.3s ease;
}

.about-button-form:hover {
    font-size: 1.2rem;
    font-weight: bold;
    transition: 0.5s ease;
}



/* Image Styles */
.main-image {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    height: 300px;
}

.main-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.small-image {
    border-radius: 8px;
    width: 100%;
    height: 150px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.small-image:hover {
    transform: scale(1.05);
}

/* Gallery Image Styles */
.gallery-image {
    /* border-radius: 8px; */
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease;
    margin-bottom: 15px;
}

.gallery-image:hover {
    transform: scale(1.05);
}

/* Make first column images larger */
.col-6 .gallery-image {
    height: 250px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .luxury-title {
        font-size: 2.5rem;
    }
}

@media (max-width: 768px) {
    .luxury-title {
        font-size: 2rem;
    }

    .about-description {
        font-size: 1rem;
    }

    .main-image {
        height: 250px;
    }

    .small-image {
        height: 120px;
    }

    .gallery-image {
        height: 150px;
    }
    
    .col-6 .gallery-image {
        height: 200px;
    }
}

@media (max-width: 480px) {
    .main-image {
        height: 200px;
    }

    .about-text{
        text-align: center;
    }

    .small-image {
        height: 100px;
    }

    .gallery-image {
        height: 120px;
    }
    
    .col-6 .gallery-image {
        height: 160px;
    }
}

/* Add these new styles */
.mobile-gallery {
    display: none;
}

.mobile-gallery-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .desktop-gallery {
        display: none !important;
    }

    .mobile-gallery {
        display: block;
    }

    .mobile-gallery-image {
        height: 250px;
    }
}

@media (max-width: 480px) {
    .mobile-gallery-image {
        height: 200px;
    }
}

/* Desktop Gallery */
.desktop-gallery {
    display: block;
}

/* Mobile Gallery */
.mobile-gallery {
    display: none;
}

.mobile-gallery-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
    transition: opacity 0.5s ease;
}

@media (max-width: 768px) {
    .desktop-gallery {
        display: none !important;
    }

    .mobile-gallery {
        display: block;
    }

    .mobile-gallery-image {
        height: 250px;
    }
}

@media (max-width: 480px) {
    .mobile-gallery-image {
        height: 200px;
    }
}