.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 8%;
    background-color: #0A0F2C;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;
    position: relative;
    max-width: 100%;
}

.navbar-brand {
    display: flex;
    align-items: center;
    margin-left: 2rem;
}

.logo {
    height: 40px;
    width: auto;
    max-width: 100%;
}

.nav-links {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 2rem;
}

.nav-item {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.nav-item:hover {
    color: rgba(246, 214, 115, 1);
}

/* Highlight HOME button */
/* .nav-item:first-child {
    background-color: rgba(246, 214, 115, 1);
    color: #0A0F2C;
    padding: 0.5rem 1rem;
    border-radius: 4px;
} */

.hamburger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    position: relative;
    z-index: 1001;
}

/* Responsive Design */
@media (max-width: 768px) {
    
    .hamburger {
        display: block;
        position: relative;
        z-index: 100;
    }

    .hamburger-line {
        display: block;
        width: 25px;
        height: 3px;
        background: #fff;
        position: relative;
        transition: all 0.3s ease-in-out;
    }

    .hamburger-line::before,
    .hamburger-line::after {
        content: '';
        position: absolute;
        width: 25px;
        height: 3px;
        background: rgba(246, 214, 115, 1);
        transition: all 0.3s ease-in-out;
    }

    .hamburger-line::before {
        transform: translateY(-8px);
    }

    .hamburger-line::after {
        transform: translateY(8px);
    }

    .hamburger-line.open {
        background: transparent;
    }

    .hamburger-line.open::before {
        transform: rotate(45deg);
    }

    .hamburger-line.open::after {
        transform: rotate(-45deg);
    }

    .nav-links {
        position: fixed;
        top: 0;
        right: -100%;
        width: 80%;
        height: 70vh;
        background-color: #0A0F2C;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: right 0.3s ease-in-out;
        padding-top: 60px;
    }

    .nav-links.active {
        right: 0;
    }

    .nav-item {
        padding: 1rem 0;
        color: rgba(246, 214, 115, 1);
    }

    .nav-item:hover {
        color: white;
    }

    .navbar-brand {
        margin-left: 1rem;
    }

    .nav-links {
        margin-right: 0;
    }
}

body {
    padding: 0;
}

.contact-info {
    display: flex;
    align-items: center;
    margin-right: 2rem;
}

.phone-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    color: #fff;
    
}



.phone-number {
    color: #fff;
    border: 1px solid rgba(246, 214, 115, 1);
    border-radius: 25px;
    padding: 5px 10px;
}

.phone-number:hover {
    background-color: rgba(246, 214, 115, 1);
    color: #0A0F2C;
    font-weight: bold;
}

.mobile-actions {
    display: none;
}

.mobile-phone-link {
    display: none;
    color: #fff;
}

.mobile-phone-link img {
    width: 30px;
    height: 30px;
}

/* .mobile-phone-link i {
    color: rgba(246, 214, 115, 1);
    width: 50px;
    height: 50px;
} */

.call-icon {
    width: 30px;
    height: 30px;
    animation: shake 1s infinite;
}

.call-icon:hover {
    animation-play-state: paused;
}

/* 1. Shake Animation */
@keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(15deg); }
    50% { transform: rotate(-15deg); }
    75% { transform: rotate(10deg); }
    100% { transform: rotate(0deg); }
}

/* 2. Pulse Animation */
@keyframes callPulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

/* 3. Ring Animation */
@keyframes ring {
    0% { transform: rotate(0); }
    10% { transform: rotate(30deg); }
    20% { transform: rotate(-28deg); }
    30% { transform: rotate(26deg); }
    40% { transform: rotate(-24deg); }
    50% { transform: rotate(22deg); }
    60% { transform: rotate(-20deg); }
    70% { transform: rotate(18deg); }
    80% { transform: rotate(-16deg); }
    90% { transform: rotate(14deg); }
    100% { transform: rotate(0); }
}

@media (max-width: 768px) {
    .nav-links {
        position: fixed;
        top: 60px; /* adjust based on your navbar height */
        left: 0;
        right: 0;
        transform: none;
        flex-direction: column;
        background: rgba(9, 18, 53, 1);
        padding: 1rem;
        display: none;
    }

    .nav-links.active {
        display: flex;
    }

    .contact-info {
        display: none;
    }

    .mobile-actions {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .mobile-phone-link {
        display: flex;
        color: #fff;
        font-size: 1.2rem;
    }
}
