.hero-container {
  width: 100%;
  height: 90vh;
  overflow: hidden;
  position: relative;
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  background-color: #000;
}

/* Slider Navigation Styles */
.slick-slider, .slick-list, .slick-track {
  height: 100%;
}

.slick-slide {
  height: 100%;
}

.slick-slide > div {
  height: 100%;
}

.slick-prev, .slick-next {
  z-index: 1;
}

.slick-prev {
  left: 25px;
}

.slick-next {
  right: 25px;
}

.slick-dots {
  bottom: 25px;
}

.slick-dots li button:before {
  color: white;
}

.slide-content {
  position: absolute;
  color: white;
  padding: 2rem;
  max-width: 500px;
  z-index: 2;
}

.slide-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "Libre Baskerville", serif;
  /* font-style:normal; */
}

.know-more-btn {
  /* padding: 0.8rem 2rem; */
  background-color: rgba(246, 214, 115, 1);
  color: rgba(9, 18, 53, 1);
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.know-more-btn:hover {
  background-color: white;
}

.slideThree {
  /* color: rgba(9, 18, 53, 1); */
  color: white;
  
}

.sticky-button {
  position: fixed;
  left: 0;
  top: 40%;
  transform: translateY(-50%);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  z-index: 1000;
  writing-mode: vertical-lr;
  background-color: rgba(246, 214, 115, 1);
  color: black;
  border: none;
  cursor: pointer;
  height: 150px;
  width: 50px;
  /* border: 2px solid rgba(9, 18, 53, 1); */
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  text-align: center;
  animation: pulse 2s infinite;
  transition: all 0.3s ease;
}

@keyframes pulse {
  0% {
      transform: translateY(-50%) scale(1);
      box-shadow: 0 0 0 0 rgba(9, 18, 53, 1);
  }
  70% {
      transform: translateY(-50%) scale(1.05);
      box-shadow: 0 0 0 10px rgba(9, 18, 53, 0.5);
  }
  100% {
      transform: translateY(-50%) scale(1);
      box-shadow: 0 0 0 0 rgba(9, 18, 53, 0.5);
  }
}

.sticky-button:hover {
  background-color: rgb(255, 212, 85);
  color: rgba(9, 18, 53, 1);
  transform: translateY(-50%) scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

/* Position variants */
.left-top {
  top: 20%;
  left: 10%;
  transform: translateY(-50%);
}

.right-top {
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  text-align: right;
}

.left-center {
  top: 30%;
  left: 10%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .hero-container {
      width: 100%;
      height: 60vh;
      overflow-x: hidden;
  }

  .slick-prev {
      left: 10px;
  }

  .slick-next {
      right: 10px;
  }

  .slick-dots {
      bottom: 15px;
  }

  .slide-content {
      padding: 1rem;
      max-width: 300px;
  }

  .slide-content h2 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
  }

  .know-more-btn {
      /* padding: 0.6rem 1.5rem; */

      font-size: 0.9rem;
  }

  .sticky-enquire-btn {
      display: none;
  }
}

@media (max-width:480px) {
  .left-top {
      top: 30%;
      left: 10%;
      transform: translateY(-50%);
  }
  .left-top h2 {
      font-size: 1.5rem;
  }
  
  .right-top {
      top: 20%;
      right: 5%;
      transform: translateY(-50%);
      text-align: right;
  }

  .right-top h2{
      font-size: 1.5rem;
  }
  
  .left-center {
      top: 20%;
      left: 10%;
      transform: translateY(-50%);
  }

  .left-center h2{
      font-size: 1.5rem;
  }

  .sticky-button {
      width: 40px;
      height: 150px;
      font-size: 15px;
      background-color: rgb(255, 212, 85);
      font-weight: bold;
  }

  /* @keyframes pulse {
      0% {
          transform: translateY(-50%) scale(1);
      }
      70% {
          transform: translateY(-50%) scale(1.03);
      }
      100% {
          transform: translateY(-50%) scale(1);
      }
  } */
}



