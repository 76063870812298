.location-section {
    position: relative;
    padding: 40px 20px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    overflow: visible;
    z-index: 1;
}

/* Blurred background layer */
.location-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../assets/images/bg1.webp');
    background-position: center;
    opacity: 0.1;
    pointer-events: none;
    z-index: -1;
}

/* Content layer */
.location-container {
    position: relative;
    max-width: 1200px;
    margin: 40px auto;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
}

.location-title {
    text-align: center;
    margin-bottom: 20px;
}

.location-title h2 {
    color: #091235;
    font-size: calc(2rem + 1vw);
    margin-bottom: 10px;
}

.title-decoration-location {
    width: 120px;
    height: auto;
}

.location-description {
    text-align: center;
    max-width: 900px;
    margin: 0 auto 30px;
    line-height: 1.5;
    font-size: 1.5rem;
    color: rgba(9, 18, 53, 1);
    padding: 0 20px;
}

.location-map-container {
    display: flex;
    gap: 30px;
    align-items: center;
    min-height: 400px;
    max-height: 600px;
    padding: 0 20px;
}

.map-section {
    flex: 2;
    height: 100%;
    display: flex;
    align-items: center;
}

.location-map {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.nearby-places {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-self: center;
    max-height: 100%;
    overflow-y: auto;
    padding-right: 10px;
}

.place-card {
    background-color: #f6d673;
    padding: 12px 20px;
    border-radius: 25px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
}

.place-card:hover {
    transform: translateY(-2px);
}

/* Custom scrollbar for nearby places */
.nearby-places::-webkit-scrollbar {
    width: 6px;
}

.nearby-places::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.nearby-places::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 992px) {
    .location-map-container {
        flex-direction: column;
        min-height: auto;
        gap: 20px;
        padding: 20px;
    }

    .map-section {
        width: 100%;
        max-height: 400px;
    }

    .nearby-places {
        width: 100%;
        max-height: 300px;
    }
}

@media (max-width: 768px) {
    .location-section {
        padding: 30px 15px;
    }

    .location-container {
        margin: 20px auto;
    }

    .location-map-container {
        padding: 10px;
    }

    .map-section {
        max-height: 300px;
    }

    .nearby-places {
        max-height: 250px;
    }
}

/* For very small screens */
@media (max-height: 600px) {
    .location-container {
        margin: 15px auto;
    }
}