.sticky-popup {
    position: fixed;
    bottom: 10%;
    right: 20px;
    z-index: 1000;
    font-family: "Poppins", sans-serif;
    background-color: rgba(246, 214, 115, 1);
}

.popup-minimized {
    background-color: rgba(246, 214, 115, 1);
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(9, 18, 53, 1);
    cursor: pointer;
}

.popup-expanded {
    background-color: white;
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.popup-header {
    background-color: rgba(246, 214, 115, 1);
    padding: 15px;
    padding-left: 10px;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(9, 18, 53, 1);
    font-size: 1.2rem;
    font-weight: bold;
}

.popup-header-title {
    flex-grow: 1;
    text-align: center;
}

.popup-header button{
    font-size: 1.5rem;
    color: black;
    margin-left: auto;
}

.popup-content {
    padding: 15px;
}

.popup-content-sticky{
    border-radius: 0px;
    background-color: rgba(246, 214, 115, 1);
    padding: 15px;
}

.submit-button-sticky {
    width: 100%;
    padding: 0.6rem;
    background-color: rgba(9, 18, 53, 1);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    /* font-weight: bold; */
  }


  .submit-button-sticky:hover {
    width: 100%;
    padding: 0.7rem;
    background-color: rgb(14, 27, 79);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
  }

.expand-btn, .close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: rgba(9, 18, 53, 1);
}

.input-group {
    margin-bottom: 15px;
    position: relative;
}

.input-group input,
.input-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
}

.input-group input.error,
.input-group select.error {
    border-color: #ff0000;
}

.error-message {
    color: #ff0000;
    font-size: 12px;
    position: absolute;
    bottom: -18px;
    left: 0;
}

.submit-button {
    width: 100%;
    background-color: rgba(246, 214, 115, 1);
    color: rgba(9, 18, 53, 1);
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: rgb(255, 212, 85);
}

.submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .popup-expanded {
        width: 90vw;
        max-width: 300px;
    }
    
    .sticky-popup {
        bottom: 10px;
        right: 10px;
    }
} 


@media (max-width:480px) {
    .sticky-popup {
        bottom: 10%;
    }
}