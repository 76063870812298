.gallery-section {
    padding: 80px 0;
    background: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.gallery-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../assets/images/bg1.webp');
    background-position: center;
    opacity: 0.1;
    pointer-events: none;
    z-index: -1;
}

.gallery-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    max-width: 1800px;
    margin: 0 auto;
}

.gallery-title {
    text-align: center;
    position: relative;
    padding-bottom: 3rem;
    margin-bottom: 2rem;
    max-width: 800px;
    margin: 0 auto 2rem;
}

.gallery-title h2 {
    margin: 0;
    font-size: 2rem;
    color: #1a1a3d;
    margin-bottom: 1.5rem;
}

.decorative-image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 100px;
    height: auto;
    display: block;
    clear: both;
}

.slide-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    transition: transform 0.5s ease-in-out;
    border-radius: 15px;
}

.slide-gallery img {
    width: 100%;
    border-radius: 15px;
    height: auto;
}

.slider-button-gallery {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.slider-button-gallery.prev {
    left: 10px;
}

.slider-button-gallery.next {
    right: 10px;
}

.gallery-button {
    text-align: center;
    padding-top: 1rem;
}

.gallery-button-form {
    background-color: rgba(9, 18, 53, 1);
    padding: 0.5rem 1rem;
    border-radius: 25px;
    border: none;
    font-size: 1.2rem;
    font-weight: 700;
    color: rgba(246, 214, 115, 1);
    margin-top: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.gallery-button-form:hover {
    transform: scale(1.05);
    color: white;
}

.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 60vh;
    max-width: 1600px;
    margin: 1rem auto;
    position: relative;
    padding: 0 20px;
}

.main-image {
    flex: 3;
    height: 100%;
    max-height: 600px;
    position: relative;
    border-radius: 17px;
}

.main-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* border-radius: 15px; */
}

.side-image {
    flex: 1;
    height: 70%;
    max-height: 400px;
    position: relative;
    border-radius: 17px;
    transition: all 0.3s ease;
}

.side-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    opacity: 0.8;
    background-color: rgba(9, 18, 53, 1);
}

.carousel-controls {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.control-button {
    background-color: transparent;
    border: 2px solid #1a1a3d;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1a1a3d;
}

.button-gallery {
    text-align: center;
    
}

.image-description {
    font-family: "Libre Baskerville", serif;
    font-weight: 500;
    text-align: center;
    margin: 1rem auto 2rem;
    margin-bottom: 0.5rem;
    max-width: 800px;
    padding: 0 20px;
}

.image-description p {
    font-size: 1.2rem;
    color: #1a1a3d;
    line-height: 1.6;
    margin: 0;
}

/* Update media query for smaller screens */
@media screen and (max-width: 768px) {
    .gallery-section {
        padding: 40px 0;
    }

    

    .gallery-title {
        padding-bottom: 2rem;
        margin-bottom: 1rem;
    }

    .gallery-title h2 {
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }

    .decorative-image {
        width: 80px;
        bottom: 5px;
    }

    .carousel-container {
        height: auto;
        min-height: 300px;
        gap: 0;
        padding: 0 10px;
        margin: 0.5rem auto;
    }

    .main-image {
        flex: 1;
        height: 350px;
        max-height: none;
    }

    .main-image img {
        object-fit: cover;
    }

    .side-image {
        display: none;
    }

    .carousel-controls {
        margin-top: 0.5rem;
    }

    .image-description {
        padding: 0 15px;
        margin: 0.5rem auto;
    }

    .image-description p {
        font-size: 1rem;
    }

    .gallery-button-form {
        padding: 0.8rem 1.5rem;
        font-size: 1rem;
        margin-top: 0.5rem;
    }
}

@media screen and (max-width: 480px) {
    .gallery-section {
        padding: 30px 0;
    }

    .main-image {
        height: 250px;
    }

    .gallery-title h2 {
        font-size: 1.5rem;
    }

    .gallery-title {
        padding-bottom: 1.5rem;
    }

    .gallery-title h2 {
        margin-bottom: 1.5rem;
    }

    .decorative-image {
        width: 60px;
        bottom: 0;
    }
}


