.contact-section {
    background-color: rgba(9, 18, 53, 1);
    color: white;
    padding-top: 50px;
    padding-bottom: 5px;
    
}

.row {
    display: flex;
    align-items: stretch;
    max-width: 1200px;
    margin: 0 auto;
}



.contact-left {
    padding: 40px;
    text-align: center;
    

}

.contact-left h2 {
    /* font-family: 'Poppins', sans-serif; */
    font-size: 3rem;
    margin-bottom: 40px;
}

.highlight {
    color: rgba(246, 214, 115, 1);
}

.address-section{
    margin-top: 30px;
    text-align: center;
}

.address-section,
.call-section,
.email-section {
    margin-bottom: 40px;
    margin-top: 30px;
}

.contact-left h3 {
    color: rgba(246, 214, 115, 1);
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.contact-left p {
    margin-bottom: 8px;
    font-size: 1.1rem;
}

.contact-left a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-left a:hover {
    color: rgba(246, 214, 115, 1);
}

.social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
}

.social-icon {
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: rgba(246, 214, 115, 1);
}

.contact-form {
    background-color: rgba(246, 214, 115, 1);
    padding: 40px;
    border-radius: 10px;
    height: 100%;
}

.contact-form h2 {
    color: #0a1435;
    font-size: 2rem;
    margin-bottom: 30px;
}

.form-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;
    background-color: white;
}

.submit-button-contact {
    width: 100%;
    padding: 12px;
    background-color: #0a1435;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.submit-button-contact:hover {
    opacity: 0.9;
}

.footer-bottom{
    text-align: center;
    margin-top: 1.5rem;
    bottom: 0;
    position: relative;
    padding-top: 1rem;
    line-height: 1rem;
    font-size: 1rem;
}

.footer-bottom::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 0.2px;
    background-color: silver;
}

@media (max-width: 768px) {
    .row {
        flex-direction: column;
    }

    .contact-left {
        order: 2;
        padding: 20px;
    }

    .col-lg-2 {
        display: none;
    }

    .col-lg-4 {
        order: 1;
        margin-bottom: 30px;
        width: 100%;
    }

    .contact-form {
        padding: 20px;
        margin: 0 20px;
    }

    .contact-left h2 {
        font-size: 2.5rem;
    }

    .address-section,
    .call-section,
    .email-section {
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .social-links {
        margin-top: 20px;
    }

    .footer-bottom {
        margin-top: 2rem;
    }
}
