.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: rgba(246, 214, 115, 1);
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  position: relative;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  color: rgba(9, 18, 53, 1);
}

.popup-title {
  text-align: center;
  margin-bottom: 1.5rem;
  color: rgba(9, 18, 53, 1);
}

.form-input {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background-color: rgba(9, 18, 53, 1);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.submit-button:hover {
  background-color: #152f28;
}

.input-group {
  margin-bottom: 1rem;
  position: relative;
}

.error-message {
  color: #ff0000;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  display: block;
}

.form-input.error {
  border: 1px solid #ff0000;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
