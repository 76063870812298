.price-container {
    min-height: 100vh;
    background-color: rgba(9, 18, 53, 1);
    color: rgba(246, 214, 115, 1);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    
}

.highlights {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.highlight-item {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 200px;
    padding: 1rem;
    border: 2px solid rgba(246, 214, 115, 1);
    border-radius: 15px;
}

.highlight-item img {
    width: 60px;
    height: 60px;
    margin-bottom: 0.5rem;
}

.highlight-item div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.highlight-number {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.highlight-text {
    white-space: nowrap;
}

.price-section {
    text-align: center;
}

.price-title {
    position: relative;
}

.price-title h2 {
    font-size: 2.5rem;
    color: rgba(246, 214, 115, 1);
}

.title-decoration {
    width: 200px;
    height: 100px;
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.price-table {
    background-color: rgba(246, 214, 115, 1);
    color: rgba(9, 18, 53, 1);
    border-radius: 15px;
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
}

.table-header, .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1rem 0;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
}

.table-row {
    border-top: 1px solid rgba(10, 20, 51, 0.1);
}

.unlock-btn {
    background-color: #0a1433;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
}

.unlock-btn:hover {
    opacity: 0.9;
}

@media (max-width: 768px) {
    .highlights {
        gap: 1rem;
    }
    
    .highlight-item {
        width: 160px;
    }
    
    .table-header, .table-row {
        font-size: 0.9rem;
    }
}
